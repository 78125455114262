import React, { Suspense } from 'react'
import { Router } from 'react-router-dom'
import { renderAuthenticated, renderUnAuthenticated } from './navigator'
import Fallback from './shared/Fallback'
import { history } from './reducers'

function App(): JSX.Element {
  return (
    <Suspense fallback={<Fallback />}>
      <Router history={history}>
        {renderAuthenticated()}
        {renderUnAuthenticated()}
      </Router>
    </Suspense>
  )
}

export default App
