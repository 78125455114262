import { IError } from '../types/error'
import {
  AuthActionTypes,
  LOGIN_FAILURE,
  LOGIN_REQUESTED,
  LOGIN_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_REQUESTED,
  RESET_AUTH_STATUS,
  LOGOUT_SUCCESS,
  CHANGE_INTERVAL_REQUESTED,
  CHANGE_INTERVAL_SUCCESS,
  CHANGE_INTERVAL_FAILURE,
} from '../types/user'

interface AuthState {
  requesting: boolean
  error: IError | null
  status: number | null
}

const initialState: AuthState = {
  requesting: false,
  error: null,
  status: null,
}

function User(
  state: AuthState = initialState,
  action: AuthActionTypes,
): AuthState {
  switch (action.type) {
    case LOGIN_REQUESTED:
    case LOGOUT_REQUESTED:
    case CHANGE_INTERVAL_REQUESTED:
      return {
        ...state,
        requesting: true,
        error: null,
        status: null,
      }
    case LOGIN_SUCCESS:
    case LOGOUT_SUCCESS:
    case CHANGE_INTERVAL_SUCCESS:
      return {
        ...state,
        requesting: false,
        error: null,
      }
    case LOGIN_FAILURE:
    case LOGOUT_FAILURE:
    case CHANGE_INTERVAL_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.error,
      }
    case RESET_AUTH_STATUS:
      return initialState
    default:
      return state
  }
}

export default User
