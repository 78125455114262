import Api from './api'
import resetOnLogout from '../utils/resetOnLogout'

interface IRequestType {
  uri: string
  method: (uri: string, params?: unknown) => Promise<Response>
  params?: Record<string, unknown>
}

const handleRequest = async (request: IRequestType): Promise<Response> => {
  const { method, uri, params } = request
  const response = await method(uri, params)
  if (response.status === 401) resetOnLogout()
  return response
}

const ApiWrapper = (api: Api) => ({
  get: async (uri: string): Promise<Response> =>
    handleRequest({
      uri,
      method: api.get,
    } as IRequestType),
  post: async (uri: string, params = {}): Promise<Response> =>
    handleRequest({
      uri,
      method: api.post,
      params,
    } as IRequestType),
  put: async (uri: string, params = {}): Promise<Response> =>
    handleRequest({
      uri,
      method: api.put,
      params,
    } as IRequestType),
  delete: async (uri: string, params = {}): Promise<Response> =>
    handleRequest({
      uri,
      method: api.delete,
      params,
    } as IRequestType),
})

const wrapper = ApiWrapper(new Api())
export default wrapper
