import { IError } from '../types/error'
import {
  GET_ARRIVED_INVOICES_FAILURE,
  GET_ARRIVED_INVOICES_REQUEST,
  GET_ARRIVED_INVOICES_SUCCESS,
  GET_UPLOADED_INVOICES_FAILURE,
  GET_UPLOADED_INVOICES_REQUEST,
  GET_UPLOADED_INVOICES_SUCCESS,
  IInvoice,
  InvoicesActionTypes,
  IUpdateInvoiceRequest,
  RESET_INVOICES_STATUS,
  SHOW_LOADER,
  VALIDATE_INVOICE_FAILURE,
  VALIDATE_INVOICE_REQUEST,
  VALIDATE_INVOICE_SUCCESS,
} from '../types/invoices'

export const getArrivedInvoices = (): InvoicesActionTypes => ({
  type: GET_ARRIVED_INVOICES_REQUEST,
})

export const getArrivedInvoicesSuccess = (
  validInvoices: IInvoice[],
  invalidInvoices: IInvoice[],
): InvoicesActionTypes => ({
  type: GET_ARRIVED_INVOICES_SUCCESS,
  validInvoices,
  invalidInvoices,
})

export const getArrivedInvoicesFailure = (
  error: IError,
): InvoicesActionTypes => ({
  type: GET_ARRIVED_INVOICES_FAILURE,
  error,
})

export const getUploadedInvoices = (): InvoicesActionTypes => ({
  type: GET_UPLOADED_INVOICES_REQUEST,
})

export const getUploadedInvoicesSuccess = (
  uploadedInvoices: IInvoice[],
): InvoicesActionTypes => ({
  type: GET_UPLOADED_INVOICES_SUCCESS,
  uploadedInvoices,
})

export const getUploadedInvoicesFailure = (
  error: IError,
): InvoicesActionTypes => ({
  type: GET_UPLOADED_INVOICES_FAILURE,
  error,
})

export const validateInvoice = (
  data: IUpdateInvoiceRequest,
  id: number,
): InvoicesActionTypes => ({
  type: VALIDATE_INVOICE_REQUEST,
  data,
  id,
})

export const validateInvoiceSuccess = (
  validInvoices: IInvoice[],
  invalidInvoices: IInvoice[],
): InvoicesActionTypes => ({
  type: VALIDATE_INVOICE_SUCCESS,
  validInvoices,
  invalidInvoices,
})

export const validateInvoiceFailure = (error: IError): InvoicesActionTypes => ({
  type: VALIDATE_INVOICE_FAILURE,
  error,
})

export const resetInvoicesStatus = (): InvoicesActionTypes => ({
  type: RESET_INVOICES_STATUS,
})

export const showLoader = (loader: boolean): InvoicesActionTypes => ({
  type: SHOW_LOADER,
  loader,
})
