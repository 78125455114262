import { IError } from '../types/error'
import {
  AuthActionTypes,
  CHANGE_INTERVAL_FAILURE,
  CHANGE_INTERVAL_REQUESTED,
  CHANGE_INTERVAL_SUCCESS,
  ISigninRequest,
  LOGIN_FAILURE,
  LOGIN_REQUESTED,
  LOGIN_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_REQUESTED,
  LOGOUT_SUCCESS,
  RESET_AUTH_STATUS,
} from '../types/user'

export const login = (credentials: ISigninRequest): AuthActionTypes => ({
  type: LOGIN_REQUESTED,
  ...credentials,
})

export const loginSuccess = (): AuthActionTypes => ({
  type: LOGIN_SUCCESS,
})

export const loginFailure = (error: IError): AuthActionTypes => ({
  type: LOGIN_FAILURE,
  error,
})

export const logout = (): AuthActionTypes => ({
  type: LOGOUT_REQUESTED,
})

export const logoutSuccess = (): AuthActionTypes => ({
  type: LOGOUT_SUCCESS,
})

export const logoutFailure = (error: IError): AuthActionTypes => ({
  type: LOGOUT_FAILURE,
  error,
})

export const changeInterval = (interval: number): AuthActionTypes => ({
  type: CHANGE_INTERVAL_REQUESTED,
  interval,
})

export const changeIntervalSuccess = (): AuthActionTypes => ({
  type: CHANGE_INTERVAL_SUCCESS,
})

export const changeIntervalFailure = (error: IError): AuthActionTypes => ({
  type: CHANGE_INTERVAL_FAILURE,
  error,
})

export const resetAuthStatus = (): AuthActionTypes => ({
  type: RESET_AUTH_STATUS,
})
