import { IError } from '../error'
import { ISigninRequest, IUser } from './models'

export type { ISigninRequest, IUser }

export const LOGIN_REQUESTED = 'LOGIN_REQUESTED'
export interface LoginRequestAction extends ISigninRequest {
  type: typeof LOGIN_REQUESTED
}

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export interface LoginSuccessAction {
  type: typeof LOGIN_SUCCESS
}

export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export interface LoginFailureAction {
  type: typeof LOGIN_FAILURE
  error: IError
}

export const LOGOUT_REQUESTED = 'LOGOUT_REQUESTED'
export interface LogoutRequestAction {
  type: typeof LOGOUT_REQUESTED
}

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export interface LogoutSuccessAction {
  type: typeof LOGOUT_SUCCESS
}

export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'
export interface LogoutFailureAction {
  type: typeof LOGOUT_FAILURE
  error: IError
}

export const CHANGE_INTERVAL_REQUESTED = 'CHANGE_INTERVAL_REQUESTED'
export interface ChangeIntervalRequestAction {
  type: typeof CHANGE_INTERVAL_REQUESTED
  interval: number
}

export const CHANGE_INTERVAL_SUCCESS = 'CHANGE_INTERVAL_SUCCESS'
export interface ChangeIntervalSuccessAction {
  type: typeof CHANGE_INTERVAL_SUCCESS
}

export const CHANGE_INTERVAL_FAILURE = 'CHANGE_INTERVAL_FAILURE'
export interface ChangeIntervalFailureAction {
  type: typeof CHANGE_INTERVAL_FAILURE
  error: IError
}

export const RESET_AUTH_STATUS = 'RESET_AUTH_STATUS'
export interface ResetAuthStatusAction {
  type: typeof RESET_AUTH_STATUS
}

export type AuthActionTypes =
  | LoginRequestAction
  | LoginSuccessAction
  | LoginFailureAction
  | LogoutRequestAction
  | LogoutSuccessAction
  | LogoutFailureAction
  | ChangeIntervalRequestAction
  | ChangeIntervalSuccessAction
  | ChangeIntervalFailureAction
  | ResetAuthStatusAction
