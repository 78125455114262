import React, { lazy } from 'react'
import { Switch, Route } from 'react-router-dom'
import { routes } from './routes'

const SignIn = lazy(() => import('../pages/SignIn'))
const Home = lazy(() => import('../pages/Home'))

const { signin, home } = routes

function HomeSwitch(): JSX.Element {
  return (
    <Switch>
      <Route exact path={home}>
        <Home />
      </Route>
    </Switch>
  )
}

function SignInSwitch(): JSX.Element {
  return (
    <Switch>
      <Route path={signin}>
        <SignIn />
      </Route>
    </Switch>
  )
}

export const renderAuthenticated = (): JSX.Element => <HomeSwitch />
export const renderUnAuthenticated = (): JSX.Element => <SignInSwitch />
