import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import User from './user'
import Invoices from './invoices'

export const history = createBrowserHistory()

const rootReducer = combineReducers({
  router: connectRouter(history),
  user: User,
  invoices: Invoices,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
