import {
  put,
  call,
  takeLatest,
  PutEffect,
  CallEffect,
} from 'redux-saga/effects'
import {
  LOGIN_REQUESTED,
  LOGOUT_REQUESTED,
  ISigninRequest,
  CHANGE_INTERVAL_REQUESTED,
  ChangeIntervalRequestAction,
  LoginRequestAction,
  AuthActionTypes,
} from '../types/user'
import ApiWrapper from '../services/apiWrapper'
import { ApiResponse } from '../types/apiResponse'
import {
  changeIntervalFailure,
  changeIntervalSuccess,
  loginFailure,
  loginSuccess,
  logoutFailure,
  logoutSuccess,
} from '../actions/user'
import resetOnLogout from '../utils/resetOnLogout'
import { history } from '../reducers'
import { routes } from '../navigator/routes'

const loginUrl = '/auth/signin'
const logoutUrl = '/auth/signout'
const getCSRFTokenUrl = '/auth/csrf'
const changeIntervalUrl = '/auth/interval'

const apiCall = ApiWrapper

export const getCSRFTokenApi = async (): Promise<ApiResponse | null> => {
  try {
    const response: Response = await apiCall.get(getCSRFTokenUrl)
    const json = await response.json()
    return json?.data?.CSRFToken
  } catch (err) {
    return null
  }
}

const loginApi = async (credentials: ISigninRequest): Promise<ApiResponse> => {
  const response: Response = await apiCall.post(loginUrl, credentials)
  const json = await response.json()
  return json
}

const logoutApi = async (): Promise<ApiResponse> => {
  const response: Response = await apiCall.get(logoutUrl)
  const json = await response.json()
  return json
}

const changeIntervalApi = async (interval: number): Promise<ApiResponse> => {
  const response: Response = await apiCall.put(changeIntervalUrl, { interval })
  const json = await response.json()
  return json
}

const login = function* (
  payload: LoginRequestAction,
): Generator<CallEffect<any> | PutEffect<AuthActionTypes>, void, ApiResponse> {
  try {
    const { type, ...credentials } = payload
    const response: ApiResponse = yield call(loginApi, credentials)
    const { status, data, error } = response
    if (status !== 200) {
      yield put(loginFailure({ status, msg: error }))
    } else {
      history.push(routes.home)
      yield put(loginSuccess())
      localStorage.setItem('user', JSON.stringify(data))
    }
  } catch (e) {
    yield put(
      loginFailure({
        status: null,
        msg: e as string,
      }),
    )
  }
}

const changeIntervalSaga = function* (
  payload: ChangeIntervalRequestAction,
): Generator<CallEffect<any> | PutEffect<AuthActionTypes>, void, ApiResponse> {
  try {
    const response: ApiResponse = yield call(
      changeIntervalApi,
      payload.interval,
    )
    const { status, data, error } = response
    if (status !== 200) {
      yield put(changeIntervalFailure({ status, msg: error }))
    } else {
      history.push(routes.home)
      yield put(changeIntervalSuccess())
      localStorage.setItem('user', JSON.stringify(data))
    }
  } catch (e) {
    yield put(
      changeIntervalFailure({
        status: null,
        msg: e as string,
      }),
    )
  }
}

const logout = function* (): Generator<
  CallEffect<any> | PutEffect<AuthActionTypes>,
  void,
  ApiResponse
> {
  try {
    const response: ApiResponse = yield call(logoutApi)
    const { status, error } = response
    if (status !== 200) {
      yield put(logoutFailure({ status, msg: error || '' }))
    } else {
      yield put(logoutSuccess())
    }
  } catch (e) {
    yield put(logoutFailure({ status: null, msg: 'Tuntematon virhe' }))
  } finally {
    yield call(resetOnLogout)
  }
}

const watchUser = function* (): Generator {
  yield takeLatest(LOGIN_REQUESTED, login)
  yield takeLatest(LOGOUT_REQUESTED, logout)
  yield takeLatest(CHANGE_INTERVAL_REQUESTED, changeIntervalSaga)
}

export default watchUser
