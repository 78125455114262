import React from 'react'

function Fallback(): JSX.Element {
  return (
    <div className="container">
      <p>Loading...</p>
    </div>
  )
}

export default Fallback
