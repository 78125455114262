import { put, call, takeLatest } from 'redux-saga/effects'
import ApiWrapper from '../services/apiWrapper'
import { ApiResponse } from '../types/apiResponse'
import {
  GET_ARRIVED_INVOICES_REQUEST,
  GET_UPLOADED_INVOICES_REQUEST,
  IUpdateInvoiceRequest,
  VALIDATE_INVOICE_REQUEST,
} from '../types/invoices'
import {
  getArrivedInvoicesSuccess,
  getArrivedInvoicesFailure,
  validateInvoiceSuccess,
  validateInvoiceFailure,
  getUploadedInvoicesFailure,
  getUploadedInvoicesSuccess,
  showLoader,
} from '../actions/invoices'

const apiCall = ApiWrapper

const getArrivedInvoicesUrl = '/invoices'
const getUploadedInvoicesUrl = '/invoices/uploaded'
const validateInvoiceUrl = '/invoices/%id'

const getArrivedInvoicesApi = async (): Promise<ApiResponse> => {
  const response: Response = await apiCall.get(getArrivedInvoicesUrl)
  const json = await response.json()
  return json
}

const getUploadedInvoicesApi = async (): Promise<ApiResponse> => {
  const response: Response = await apiCall.get(getUploadedInvoicesUrl)
  const json = await response.json()
  return json
}

const validateInvoiceApi = async (
  id: number,
  body: IUpdateInvoiceRequest,
): Promise<ApiResponse> => {
  const response: Response = await apiCall.put(
    validateInvoiceUrl.replace('%id', id.toString()),
    body,
  )
  const json = await response.json()
  return json
}

const getArrivedInvoices = function* (): Generator<any, void, any> {
  try {
    yield put(showLoader(true))
    const response: ApiResponse = yield call(getArrivedInvoicesApi)
    const { status, data, error } = response
    yield put(showLoader(false))
    if (status !== 200) {
      yield put(getArrivedInvoicesFailure({ status, msg: error }))
    } else {
      const { validInvoices, invalidInvoices } = data
      yield put(getArrivedInvoicesSuccess(validInvoices, invalidInvoices))
    }
  } catch (e) {
    yield put(showLoader(false))
    yield put(getArrivedInvoicesFailure({ status: null, msg: e }))
  }
}

const getUploadedInvoices = function* (): Generator<any, void, any> {
  try {
    yield put(showLoader(true))
    const response: ApiResponse = yield call(getUploadedInvoicesApi)
    const { status, data, error } = response
    yield put(showLoader(false))
    if (status !== 200) {
      yield put(getUploadedInvoicesFailure({ status, msg: error }))
    } else {
      yield put(getUploadedInvoicesSuccess(data))
    }
  } catch (e) {
    yield put(showLoader(false))
    yield put(getUploadedInvoicesFailure({ status: null, msg: e }))
  }
}

const validateInvoice = function* ({
  id,
  data: body,
}: any): Generator<any, void, any> {
  try {
    const response: ApiResponse = yield call(validateInvoiceApi, id, body)
    const { status, data, error } = response
    if (status !== 200) {
      yield put(validateInvoiceFailure({ status, msg: error }))
    } else {
      const { validInvoices, invalidInvoices } = data
      yield put(validateInvoiceSuccess(validInvoices, invalidInvoices))
    }
  } catch (e) {
    yield put(validateInvoiceFailure({ status: null, msg: e }))
  }
}

const watchInvoices = function* (): Generator {
  yield takeLatest(GET_ARRIVED_INVOICES_REQUEST, getArrivedInvoices)
  yield takeLatest(GET_UPLOADED_INVOICES_REQUEST, getUploadedInvoices)
  yield takeLatest(VALIDATE_INVOICE_REQUEST, validateInvoice)
}

export default watchInvoices
