import { IError } from '../error'
import { IInvoice, IUpdateInvoiceRequest } from './models'

export type { IInvoice, IUpdateInvoiceRequest }

export const GET_ARRIVED_INVOICES_REQUEST = 'GET_ARRIVED_INVOICES_REQUEST'
export interface GetArrivedInvoicesRequestAction {
  type: typeof GET_ARRIVED_INVOICES_REQUEST
}

export const GET_ARRIVED_INVOICES_SUCCESS = 'GET_ARRIVED_INVOICES_SUCCESS'
export interface GetArrivedInvoicesSuccessAction {
  type: typeof GET_ARRIVED_INVOICES_SUCCESS
  validInvoices: IInvoice[]
  invalidInvoices: IInvoice[]
}

export const GET_ARRIVED_INVOICES_FAILURE = 'GET_ARRIVED_INVOICES_FAILURE'
export interface GetArrivedInvoicesFailureAction {
  type: typeof GET_ARRIVED_INVOICES_FAILURE
  error: IError
}

export const GET_UPLOADED_INVOICES_REQUEST = 'GET_UPLOADED_INVOICES_REQUEST'
export interface GetUploadedInvoicesRequestAction {
  type: typeof GET_UPLOADED_INVOICES_REQUEST
}

export const GET_UPLOADED_INVOICES_SUCCESS = 'GET_UPLOADED_INVOICES_SUCCESS'
export interface GetUploadedInvoicesSuccessAction {
  type: typeof GET_UPLOADED_INVOICES_SUCCESS
  uploadedInvoices: IInvoice[]
}

export const GET_UPLOADED_INVOICES_FAILURE = 'GET_UPLOADED_INVOICES_FAILURE'
export interface GetUploadedInvoicesFailureAction {
  type: typeof GET_UPLOADED_INVOICES_FAILURE
  error: IError
}

export const VALIDATE_INVOICE_REQUEST = 'VALIDATE_INVOICE_REQUEST'
export interface ValidateInvoiceRequestAction {
  type: typeof VALIDATE_INVOICE_REQUEST
  data: IUpdateInvoiceRequest
  id: number
}

export const VALIDATE_INVOICE_SUCCESS = 'VALIDATE_INVOICE_SUCCESS'
export interface ValidateInvoiceSuccessAction {
  type: typeof VALIDATE_INVOICE_SUCCESS
  validInvoices: IInvoice[]
  invalidInvoices: IInvoice[]
}

export const VALIDATE_INVOICE_FAILURE = 'VALIDATE_INVOICE_FAILURE'
export interface ValidateInvoiceFailureAction {
  type: typeof VALIDATE_INVOICE_FAILURE
  error: IError
}

export const RESET_INVOICES_STATUS = 'RESET_INVOICES_STATUS'
export interface ResetInvoicesStatusAction {
  type: typeof RESET_INVOICES_STATUS
}

export const SHOW_LOADER = 'SHOW_LOADER'
export interface ShowLoaderAction {
  type: typeof SHOW_LOADER
  loader: boolean
}

export type InvoicesActionTypes =
  | GetArrivedInvoicesRequestAction
  | GetArrivedInvoicesSuccessAction
  | GetArrivedInvoicesFailureAction
  | GetUploadedInvoicesRequestAction
  | GetUploadedInvoicesSuccessAction
  | GetUploadedInvoicesFailureAction
  | ValidateInvoiceRequestAction
  | ValidateInvoiceSuccessAction
  | ValidateInvoiceFailureAction
  | ResetInvoicesStatusAction
  | ShowLoaderAction
