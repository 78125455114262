import { IError } from '../types/error'
import {
  GET_ARRIVED_INVOICES_FAILURE,
  GET_ARRIVED_INVOICES_REQUEST,
  GET_ARRIVED_INVOICES_SUCCESS,
  GET_UPLOADED_INVOICES_FAILURE,
  GET_UPLOADED_INVOICES_REQUEST,
  GET_UPLOADED_INVOICES_SUCCESS,
  IInvoice,
  InvoicesActionTypes,
  RESET_INVOICES_STATUS,
  SHOW_LOADER,
  VALIDATE_INVOICE_FAILURE,
  VALIDATE_INVOICE_REQUEST,
  VALIDATE_INVOICE_SUCCESS,
} from '../types/invoices'

export interface InvoicesState {
  error: IError | null
  validInvoices: IInvoice[]
  invalidInvoices: IInvoice[]
  uploadedInvoices: IInvoice[]
  targetInvoice: number | null
  requesting: boolean
  loader: boolean
}

const initialState: InvoicesState = {
  error: null,
  validInvoices: [],
  invalidInvoices: [],
  uploadedInvoices: [],
  targetInvoice: null,
  requesting: false,
  loader: false,
}

function Invoices(
  state: InvoicesState = initialState,
  action: InvoicesActionTypes,
): InvoicesState {
  switch (action.type) {
    case GET_ARRIVED_INVOICES_REQUEST:
    case GET_UPLOADED_INVOICES_REQUEST:
    case VALIDATE_INVOICE_REQUEST:
      return {
        ...state,
        requesting: true,
      }
    case GET_ARRIVED_INVOICES_SUCCESS:
      return {
        ...state,
        validInvoices: action.validInvoices,
        invalidInvoices: action.invalidInvoices,
        error: null,
        requesting: false,
      }
    case GET_UPLOADED_INVOICES_SUCCESS:
      return {
        ...state,
        uploadedInvoices: action.uploadedInvoices,
        requesting: false,
        error: null,
      }
    case VALIDATE_INVOICE_SUCCESS: {
      return {
        ...state,
        requesting: false,
        error: null,
        validInvoices: action.validInvoices,
        invalidInvoices: action.invalidInvoices,
      }
    }
    case GET_ARRIVED_INVOICES_FAILURE:
    case VALIDATE_INVOICE_FAILURE:
    case GET_UPLOADED_INVOICES_FAILURE:
      return {
        ...state,
        error: action.error,
        requesting: false,
      }

    case SHOW_LOADER:
      return {
        ...state,
        loader: action.loader,
      }

    case RESET_INVOICES_STATUS:
      return initialState
    default:
      return state
  }
}

export default Invoices
